<template>
  <div class="wrapper">
    <b-card no-body bg-variant="dark">
      <div class="animated fadeIn">
        <b-row>
          <b-col sm="12" md="8">
            <img slot="img" src="../../../../static/img/modules/oncology1.png" alt="image slot" width="100%" style="border: solid 3px #50afc9; box-shadow: 0px 2px 4px 3px #405d65; border-radius: 10px;">
            <p style="color: white;">{{ $t('wdm16.11075') }}</p>
          </b-col>
          <b-col sm="12" md="4">
            <img slot="img" src="../../../../static/img/modules/oncology2.png" alt="image slot" width="100%" style="border: solid 3px #50afc9; box-shadow: 0px 2px 4px 3px #405d65; border-radius: 10px;">
            <p style="color: white;">{{ $t('wdm16.11076') }}</p>
            <img slot="img" src="../../../../static/img/modules/oncology3.png" alt="image slot" width="100%" style="border: solid 3px #50afc9; box-shadow: 0px 2px 4px 3px #405d65; border-radius: 10px;">
            <p style="color: white;">{{ $t('wdm16.11077') }}</p>
          </b-col>
        </b-row>
        <br>
        <web-bottom></web-bottom>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WebBottom from '@/components/widgets/WebBottom';
export default {
  name: 'Oncology',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WebBottom
  },
  props: {},
  data () {
    return {
      slide: 0,
      sliding: null
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  },
  watch: {}
}

</script>

